import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@brighthr/component-button';
import * as Yup from 'yup';
import config from 'utils/config';
import FormikInput from 'components/FormikUI/FormikInput';
import emailAvailability from '../../../services/emailAvailability';
import signupGA from '../SignupGA';
import HRLiteLogo from '../../../layouts/HRLite/logo';
import SafeLiteLogo from '../../../layouts/SafeLite/brightSafe-logo';

const SignupForm = ({ onSignup, brightSafe }) => {
	const { TERMS_LINK } = config();
	const [error, setError] = useState('');
	const [saving, setSaving] = useState(false);
	const labelStyling = 'block pb-2 md:pb-6';
	const checkBoxStyling = 'flex items-center pb-2 md:pb-4';
	const validationSchemaObject = Yup.object().shape({
		firstName: Yup.string().trim().max(50).label('First name').required(),
		lastName: Yup.string().trim().max(50).label('Last name').required(),
		email: Yup.string().trim().max(50).email().label('Email address').required(),
		companyName: Yup.string().trim().label('Company name').max(50).min(0).required(),
		employerConfirmation: Yup.boolean().oneOf([true]).required(),
		termsConsent: Yup.boolean().oneOf([true]).required(),
	});
	const submitHandler = (values) => {
		const basicEmailRegex = /^\S+@\S+\.\S+$/;
		if (!basicEmailRegex.test(values.email)) return;
		setSaving(true);

		emailAvailability(values.email)
			.then((res) => {
				if (!res) {
					setError('account');
					setSaving(false);
					return;
				}
				window?.fbq?.('track', 'accountCreated');
				signupGA('Create my free account');
				onSignup(values);
			})
			.catch(() => {
				setError('system');
				setSaving(false);
			});
	};
	const LabelTitle = ({ text, forHTML }) => (
		<label className="block font-semibold" htmlFor={forHTML}>
			{text}
		</label>
	);
	return (
		<Formik
			initialValues={{
				firstName: '',
				lastName: '',
				email: '',
				companyName: '',
				employerConfirmation: false,
				termsConsent: false,
			}}
			onSubmit={submitHandler}
			validationSchema={validationSchemaObject}
			validateOnBlur
		>
			{({ values, dirty, isValid }) => (
				<Form>
					<div className="hidden w-56 lg:block">
						{brightSafe ? <SafeLiteLogo /> : <HRLiteLogo />}
					</div>
					<h3 className="my-6 text-xl font-bold text-secondary-300">
						Sign up to {brightSafe ? 'BrightSafe' : 'BrightHR'} Lite for FREE
					</h3>
					<div className="flex flex-col md:gap-2 lg:gap-4 lg:grid lg:grid-cols-2">
						<span htmlFor="firstName-sign-up" className={labelStyling}>
							<LabelTitle text="First name" forHTML="firstName-sign-up" />
							<FormikInput
								required
								name="firstName"
								id="firstName-sign-up"
								onClick={() => signupGA('First name')}
							/>
						</span>
						<span htmlFor="lastName-sign-up" className={labelStyling}>
							<LabelTitle text="Last name" forHTML="lastName-sign-up" />
							<FormikInput
								required
								name="lastName"
								id="lastName-sign-up"
								onClick={() => signupGA('Last name')}
							/>
						</span>
					</div>
					<span htmlFor="email-sign-up" className={labelStyling}>
						<LabelTitle text="Email" forHTML="email-sign-up" />
						<FormikInput
							required
							name="email"
							type="email"
							id="email-sign-up"
							onClick={() => {
								signupGA('Email');
								if (error && error === 'account') {
									setError('');
								}
							}}
						/>
						{error && (
							<div className="text-error-500">
								{error === 'account'
									? 'An account with this email address already exists'
									: 'Something went wrong'}
							</div>
						)}
					</span>
					<span htmlFor="companyName-sign-up" className={labelStyling}>
						<LabelTitle text="Company name" forHTML="companyName-sign-up" />
						<FormikInput
							name="companyName"
							id="companyName-sign-up"
							onClick={() => signupGA('Company name')}
						/>
					</span>
					<span htmlFor="employerConfirmation-sign-up" className={checkBoxStyling}>
						<Field
							type="checkbox"
							tabIndex={0}
							id="employerConfirmation"
							name="employerConfirmation"
							data-testid="termsConsentCheckBox"
							checked={values.employerConfirmation}
							onClick={() =>
								signupGA(
									`Business owner -${
										values.employerConfirmation ? 'Untick' : 'Tick'
									}`
								)
							}
						/>

						<span className="pl-4">
							I confirm that I&apos;m an{' '}
							<span className="font-semibold">employer/director/senior manager</span>
						</span>
					</span>
					<span htmlFor="termsConsent-sign-up" className={checkBoxStyling}>
						<Field
							type="checkbox"
							tabIndex={0}
							data-testid="checkbox-termsConsent"
							id="termsConsent"
							name="termsConsent"
							checked={values.termsConsent}
							onClick={() =>
								signupGA(
									`Terms and conditions -${
										values.termsConsent ? 'Untick' : 'Tick'
									}`
								)
							}
						/>

						<span className="pl-4">
							I have read and agree to the{' '}
							<a
								href={TERMS_LINK}
								rel="noreferrer"
								className="font-semibold text-brand-500 hover:underline"
							>
								terms and conditions
							</a>
						</span>
					</span>
					<div className="flex items-center mt-2">
						<Button
							type="submit"
							disabled={!dirty || !isValid || saving}
							loading={saving}
							text="Submit"
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default SignupForm;
