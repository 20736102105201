import React from 'react';
import Link from '@brighthr/component-link';
import { event } from 'utils/GA';
import config from 'utils/config';

const AlreadyRegistered = ({ gaCategory }) => {
	const { HR_URL } = config();
	return (
		<div className="flex flex-col items-center w-full">
			<h2 className="mb-4 text-xl font-bold text-center">
				You&apos;ve already registered with us.
			</h2>
			<p className="mb-6 text-center text-neutral-700">Try logging in.</p>
			<div>
				<Link
					href={`${HR_URL}/login`}
					size="lg"
					onClick={() => {
						event({
							category: gaCategory,
							action: 'Go to Login from Already Registered',
							label: '',
						});
					}}
					text="Login"
				/>
			</div>
		</div>
	);
};

export default AlreadyRegistered;
