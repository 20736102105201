import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import FullScreenLayout from 'components/FullScreenLayout';
import Loading from 'components/Loading';
import getAccountAvailability from 'services/getAccountAvailability';
import getInviteDetails from 'services/getInviteDetails';
import ErrorHandler from './ErrorHandler';
import SignIn from './SignIn';
import SignUp from './SignUp';

// TODO:
// Testing Coverage

const OwnerInvite = () => {
	const { accountId } = useParams();

	const { data, isLoading, isError, error } = useQuery(
		['get', 'invite', 'details', accountId],
		() => getInviteDetails(accountId)
	);

	const { data: accountAvailabilityData, isLoading: accountAvailabilityLoading } = useQuery(
		['get', 'account', 'availability', accountId],
		() => getAccountAvailability(data?.email),
		{
			enabled: !!data?.email,
		}
	);

	const isSignIn = accountAvailabilityData?.inviteMode === 'SignIn';

	const Component = isSignIn ? SignIn : SignUp;

	return (
		<FullScreenLayout>
			<Loading isLoading={isLoading || accountAvailabilityLoading}>
				<ErrorHandler isError={isError} error={error}>
					{data ? <Component data={data} /> : null}
				</ErrorHandler>
			</Loading>
		</FullScreenLayout>
	);
};

export default OwnerInvite;
