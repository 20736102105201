const determineInitialJurisdiction = (jurisdictions) => {
	if (!jurisdictions || jurisdictions.length === 0) {
		return '';
	}

	if (jurisdictions.length === 1) {
		return jurisdictions[0].name;
	}

	const defaultJurisdiction = jurisdictions.find((jurisdiction) => jurisdiction.default);
	return defaultJurisdiction ? defaultJurisdiction.name : '';
};

export default determineInitialJurisdiction;
