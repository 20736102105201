import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import TwoFactorAuthReport from 'pages/twoFactorAuthReport';
import Account from '../../pages/account';
import Authenticator from '../../pages/authenticator';
import ContractExpired from '../../pages/Errors/ContractExpired';
import SingleSignOn from '../../pages/singleSignOn';
import ChangePassword from '../../pages/password/changePassword';
import ManageDelegation from '../../pages/delegation';

const Routes = [
	<Route key="/sso" exact path="/sso" component={SingleSignOn} />,
	<Route key="/sso/confirm" exact path="/sso/confirm" component={SingleSignOn} />,
	<Route key="/authenticator" path="/authenticator" component={Authenticator} />,
	<Route key="/security" path="/security" component={Account} />,
	<Route key="/2fa-report" path="/2fa-report" component={TwoFactorAuthReport} />,
	<Route key="/subscription-expired" path="/subscription-expired" component={ContractExpired} />,
	<Route key="/change-password" path="/change-password" component={ChangePassword} />,
	<Route key="/manage-delegation" path="/manage-delegation" component={ManageDelegation} />,
	<Redirect key="redirect" exact from="/" to="/security" />,
];

export default Routes;
