import http from 'utils/http';
import config from 'utils/config';

const getInviteDetails = async (accountId, employee) => {
	const { ACCOUNT_API } = config();
	const uri = `${ACCOUNT_API}/v1/account/invite/${accountId}/owner`;

	return http
		.post(uri, { data: employee })
		.then((res) => res.data)
		.catch((error) => {
			throw error;
		});
};

export default getInviteDetails;
