import http from 'utils/http';
import config from 'utils/config';

const emailAvailability = (email) => {
	const { ACCOUNT_API } = config();
	const url = `${ACCOUNT_API}/v1/account/availability?username=${encodeURIComponent(email)}`;

	return http.get(url).then((res) => res.data?.available ?? false);
};

export default emailAvailability;
