import React from 'react';
import Link from '@brighthr/component-link';
import config from 'utils/config';

const SuccessfulUpdate = ({ children }) => {
	const { HR_URL } = config();
	return (
		<div className="flex flex-col items-center">
			<div data-testid="success-message" className="mb-4">
				<h2>{children}</h2>
				<div>You can now log in.</div>
			</div>
			<Link href={`${HR_URL}/login`} text="Login" />
		</div>
	);
};

export default SuccessfulUpdate;
