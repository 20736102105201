import React from 'react';
import { Route } from 'react-router-dom';

import ClaimInvite from '../../pages/claimInvite';
import RestrictAccess from '../../pages/restrictAccess';
import RestrictCountryAccess from '../../pages/restrictCountryAccess';
import Signup from '../../pages/signup';
import CreatePassword from '../../pages/password/createPassword';
import ResetPassword from '../../pages/password/resetPassword';
import OwnerInvite from '../../pages/ownerInvite';

const AuthPlaceholderRoute = () => null;

const Routes = [
	<Route key='signup"' path="/signup" exact component={Signup} />,
	<Route key="/signup/:guid" path="/signup/:guid" component={CreatePassword} />,
	<Route key="/invite/:guid/:variant?" path="/invite/:guid/:variant?" component={ClaimInvite} />,
	<Route
		key="/owner-signup/:accountId"
		path="/owner-signup/:accountId"
		component={OwnerInvite}
	/>,

	<Route key="/package-client-error" path="/package-client-error" component={RestrictAccess} />,
	<Route
		key="/country-client-error"
		path="/country-client-error"
		component={RestrictCountryAccess}
	/>,
	<Route key="/reset-password/:guid" path="/reset-password/:guid" component={ResetPassword} />,

	// Needed due to auth hook - return null
	<Route key="/logout" exact path="/logout" component={AuthPlaceholderRoute} />,
	<Route key="/login/callback" path="/login/callback" component={AuthPlaceholderRoute} />,
];

export default Routes;
