const getErrorMessage = (error) => {
	if (/Password/i.test(error.detail)) {
		const errorString = error.errors.password;
		return errorString.join(', ');
	}

	if (error.detail === 'Invalid credentials') {
		return 'Invalid credentials';
	}

	return 'Something went wrong';
};

export default getErrorMessage;
