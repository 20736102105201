import React from 'react';
import EmptyStateMessage from 'components/EmptyStateMessage';
import AlreadyRegistered from '../AlreadyRegistered';

const gaCategory = 'OwnerInvite';

const errorMessage = (errorCode) => {
	switch (errorCode) {
		case 400:
			return (
				<EmptyStateMessage
					title="Sorry something has gone wrong."
					text="Please try again."
					label="Sorry something has gone wrong."
					type="no-search-results"
					testId="Sorry something has gone wrong."
				/>
			);
		case 404:
			return (
				<EmptyStateMessage
					title="Sorry there was an issue."
					text="Your invite or user to invite can not be found. Please try the invite link again."
					label="Sorry there was an issue."
					type="no-search-results"
					testId="Sorry something has gone wrong."
				/>
			);
		case 409:
			return <AlreadyRegistered gaCategory={gaCategory} />;
		default:
			return (
				<EmptyStateMessage
					title="An error has occurred."
					text="Please try again."
					label="An error has occurred."
					type="no-search-results"
					testId="An error has occurred."
				/>
			);
	}
};

const ErrorHandler = ({ isError, error, children }) => {
	const errorCode = error?.response?.status;

	const errorMessageText = errorMessage(errorCode);

	if (isError) {
		return <div className="w-full mx-4 lg:mx-0 lg:w-1/2">{errorMessageText}</div>;
	}

	return children;
};

export default ErrorHandler;
