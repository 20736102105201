import React from 'react';
import Link from '@brighthr/component-link';
import { event } from 'utils/GA';
import Checkbox from 'components/FormikUI/FormikCheckbox';

const dotcomLinks = {
	uk: 'https://www.brighthr.com/',
	ca: 'https://www.brighthr.com/ca/',
	ie: 'https://www.brighthr.com/ie/',
	au: 'https://www.brighthr.com/au/',
};

const AcceptTermsCheckbox = ({ values, formik, gaCategory, tenant }) => {
	return (
		<div className="flex flex-row items-center mb-2">
			<Checkbox
				id="acceptTermsAndConditions"
				name="acceptTermsAndConditions"
				checked={values.acceptTermsAndConditions}
				onChange={(e) => {
					formik.handleChange(e);
					formik.setFieldValue(
						'acceptTermsAndConditions',
						!values.acceptTermsAndConditions
					);
					event({
						category: gaCategory,
						action: 'Update terms and conditions',
						label: values.acceptTermsAndConditions,
					});
				}}
			/>
			<p className="mb-0">
				I agree to the{' '}
				<Link
					textButton
					color="primary"
					href={`${dotcomLinks[tenant]}terms`}
					target="_blank"
					rel="noopener noreferrer"
					text="terms and conditions"
				/>
			</p>
		</div>
	);
};

export default AcceptTermsCheckbox;
