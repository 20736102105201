import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import Button from '@brighthr/component-button';
import Alert from '@brighthr/component-alert';
import Input from 'components/FormikUI/FormikInput';
import postInviteDetails from 'services/postInviteDetails';
import { event } from 'utils/GA';
import getErrorMessage from 'utils/getErrorMessage';
import determineInitialJurisdiction from '../determineInitialJurisdiction';
import schema from './schema';
import JurisdictionSelect from '../JurisdictionSelect';
import AcceptTermsCheckbox from '../AcceptTermsCheckbox';
import SuccessfulUpdate from '../SuccessfulUpdate';

const gaCategory = 'OwnerInvite';

const SignIn = ({ data }) => {
	const { accountId } = useParams();

	const [successfulUpdate, setSuccessfulUpdate] = useState(false);
	const [error, setError] = useState(null);

	const { email, languageCode, _embedded } = data;

	const onSubmit = (values) => {
		const payload = {
			id: accountId,
			email,
			password: values.newPassword,
			acceptTermsAndConditions: values.acceptTermsAndConditions,
			jurisdictionCode: values.jurisdiction,
		};

		return postInviteDetails(accountId, payload)
			.then(() => {
				event({
					category: gaCategory,
					action: 'Submit registration',
				});
				setSuccessfulUpdate(true);
			})
			.catch((e) => {
				const errorResponse = e?.response?.data;

				setError(getErrorMessage(errorResponse));
			});
	};

	return (
		<div className="w-full mx-4 lg:mx-0 lg:w-1/2">
			{successfulUpdate ? (
				<SuccessfulUpdate>Account created</SuccessfulUpdate>
			) : (
				<Formik
					initialValues={{
						email,
						newPassword: '',
						acceptTermsAndConditions: false,
						jurisdiction: determineInitialJurisdiction(_embedded.jurisdiction),
					}}
					validationSchema={schema}
					onSubmit={onSubmit}
				>
					{(formik, { values } = formik) => (
						<Form>
							<JurisdictionSelect
								formik={formik}
								jurisdictions={_embedded.jurisdiction}
								languageCode={languageCode}
							/>
							<h1 className="mt-4 mb-2 text-lg semi-bold">Your details</h1>
							<p>{email}</p>
							<Input
								val={values.newPassword}
								label="Password"
								type="password"
								name="newPassword"
								data-testid="newPassword"
								onChange={(e) => {
									formik.handleChange(e);
								}}
							/>
							<div className="my-2">
								<AcceptTermsCheckbox
									values={values}
									formik={formik}
									gaCategory={gaCategory}
								/>
								{error && (
									<Alert
										type="error"
										icon="error"
										title="Error"
										border
										dismiss
										fullWidth
										data-testid="error"
										className="mb-2"
									>
										{error}
									</Alert>
								)}
								<Button
									type="submit"
									disabled={
										!values.acceptTermsAndConditions || !values.jurisdiction
									}
									isLoading={formik.isSubmitting}
									text="Login"
								/>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
};

export default SignIn;
