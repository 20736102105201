import * as yup from 'yup';
import emailAvailability from 'services/emailAvailability';

let previousEmail = '';

const schema = yup.object({
	newPassword: yup.string().required('Please enter a password.'),
	acceptTermsAndConditions: yup.boolean().required(),
	jurisdiction: yup.string().required('Please select a value.'),
	email: yup
		.string()
		.label('Email')
		.email()
		.required()
		.test('checkDuplUsername', 'This email address is already taken', (email) => {
			if (email !== previousEmail) {
				previousEmail = email;
				return yup
					.string()
					.email()
					.defined()
					.isValid(email)
					.then((valid) => {
						return valid && emailAvailability(email);
					}); // This needs to be cached
			}
			return yup.string().email().defined().isValid(email);
		}),
});

export default schema;
