import React from 'react';
import Select from 'components/FormikUI/FormikSelect';

const translations = {
	'en-au': 'State or territory',
	'en-ca': 'Province',
	'en-gb': 'Region',
	'en-ie': 'Region',
	'en-nz': 'State or territory',
};

const JurisdictionSelect = ({ formik, jurisdictions, languageCode }) => {
	const jurisdictionName = translations[languageCode];
	return (
		<>
			<h1 className="mb-2 text-lg semi-bold">Your {jurisdictionName}</h1>
			<p>We use this to determine your public holiday entitlement.</p>
			<div>
				<Select
					name="jurisdiction"
					data-testid="email"
					placeholder={`Select your ${jurisdictionName}`}
					onChange={(e) => {
						formik.handleChange(e);
					}}
				>
					<option key="JURISDICTION" value="">
						Select your {jurisdictionName}
					</option>
					{jurisdictions.map((opt) => (
						<option key={opt.name} value={opt.name}>
							{opt.description}
						</option>
					))}
				</Select>
			</div>
		</>
	);
};

export default JurisdictionSelect;
