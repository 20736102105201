import React from 'react';
import Xingloader from '@brighthr/component-xingloader';

const Loading = ({ isLoading, children }) => {
	if (isLoading) {
		return (
			<div className="flex justify-center">
				<Xingloader ariaLabel="Loading..." className="stroke-primary-700" size="xxl" />
			</div>
		);
	}

	return children;
};

export default Loading;
