import React from 'react';
import cn from 'classnames';
import Icon from '@brighthr/component-icon';
import Input from 'components/FormikUI/FormikInput';

const validate = (e, values, setFieldValue) => {
	setFieldValue('validation', {
		validation0: e.currentTarget.value.match(/(?=.*[A-Z])/),
		validation1: e.currentTarget.value.match(/(?=.*[\d\W_])/),
		validation2: e.currentTarget.value.match(/[A-Za-z\d\W_]{12,}/),
		validation3: e.currentTarget.value === values.confirmPassword,
	});
};

const iconToRender = (validationIndex, index, password) => {
	if (validationIndex === undefined || password === '') return '';
	if (!validationIndex)
		return (
			<Icon
				iconName="cross-thin"
				data-testid={`cross${index}`}
				className="self-center fill-white"
			/>
		);
	return (
		<Icon
			iconName="tick-thin"
			data-testid={`cross${index}`}
			className="self-center fill-white"
		/>
	);
};

const PasswordFields = ({ formik }) => {
	const { values, touched } = formik;
	const requirements = ['1 uppercase letter', '1 number/symbol', 'Minimum 12 characters'];
	const matchPasswords = values.newPassword === values.confirmPassword;
	const passwordsTouched = touched.newPassword && touched.confirmPassword;

	return (
		<div>
			<Input
				val={values.newPassword}
				label="Password"
				type="password"
				name="newPassword"
				data-testid="newPassword"
				onChange={(e) => {
					formik.handleChange(e);
					validate(e, values, formik.setFieldValue);
				}}
			/>

			{requirements.map((requirement, index) => {
				const validationIndex = values.validation[`validation${index}`];

				return (
					<div key={requirement} className="flex items-center my-2 text-sm">
						<div className="flex items-center justify-center mr-2 h-[15px] w-[15px]">
							<div
								data-testid={`validation${index}`}
								className={cn('rounded-[50%] inline-flex', {
									'bg-error-700 h-3 w-3':
										!validationIndex && values.newPassword !== '',
									'bg-success-800 h-3 w-3':
										validationIndex && values.newPassword !== '',
									'bg-neutral-500 h-[6px] w-[6px]': values.newPassword === '',
								})}
							>
								{iconToRender(validationIndex, index, values.newPassword)}
							</div>
						</div>
						{requirement}
					</div>
				);
			})}
			<Input
				val={values.confirmPassword}
				label="Confirm password"
				type="password"
				name="confirmPassword"
				data-testid="confirmPassword"
				onChange={(e) => {
					formik.handleChange(e);
					formik.setFieldValue('validation', {
						...values.validation,
						validation3: e.currentTarget.value === values.newPassword,
					});
				}}
			/>
			{!matchPasswords && passwordsTouched && (
				<p className="text-error-500">
					Sorry, the passwords you have entered do not match, please try again
				</p>
			)}
		</div>
	);
};

export default PasswordFields;
