import React, { useEffect, useState, useRef } from 'react';

import LinkButton from '@brighthr/component-linkbutton';
import Button from '@brighthr/component-button';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';

import account from 'services/account/get';
import generateNewRecoveryCodes from 'services/2fa/generateNewRecoveryCodes';
import disable from 'services/2fa/disable';
import { event } from 'utils/GA';
import RecoveryCodes from '../../../authenticator/components/RecoveryCodes';
import CodeOptions from '../../../authenticator/components/RecoveryCodes/CodeOptions';
import redMobile from './assets/redMobile.svg';
import greenMobile from './assets/greenMobile.svg';

const TwoFactorAuth = () => {
	const [multiFactorEnabled, setMultiFactorEnabled] = useState(null);
	const [showTurnOffModal, setShowTurnOffModal] = useState(false);
	const [newRecoveryCodes, setNewRecoveryCodes] = useState([]);
	const componentRef = useRef();

	useEffect(() => {
		account().then(({ multiFactor: { enabled } }) => setMultiFactorEnabled(enabled));
	}, []);

	return (
		<>
			{newRecoveryCodes.length !== 0 && (
				<Modal close={() => setNewRecoveryCodes([])} title="Your new recovery codes">
					<ModalBody data-testid="recovery-codes" width="sm">
						<RecoveryCodes recoveryCodes={newRecoveryCodes} ref={componentRef} />
						<CodeOptions recoveryCodes={newRecoveryCodes} ref={componentRef} />
					</ModalBody>
					<ModalFooter withoutBG>
						<Button
							outlineButton
							text="Close"
							onClick={() => setNewRecoveryCodes([])}
						/>
					</ModalFooter>
				</Modal>
			)}
			{showTurnOffModal && (
				<Modal
					title="Turn off Two-Factor authentication"
					width="sm"
					close={() => setShowTurnOffModal(false)}
				>
					<ModalBody>
						<p className="mb-5">
							Are you sure? If you switch off this feature, you&apos;ll lose an extra
							level of protection on your account. You&apos;ll also have to set it up
							from scratch if you ever want to re-use it again.
						</p>
					</ModalBody>
					<ModalFooter withoutBG>
						<Button
							outlineButton
							onClick={() => setShowTurnOffModal(false)}
							text="Cancel"
						/>
						<Button
							onClick={() =>
								disable().then(() => {
									event({
										category: '2fa',
										action: '2fa disabled',
									});
									setMultiFactorEnabled(false);
									setShowTurnOffModal(false);
								})
							}
							text="Continue"
						/>
					</ModalFooter>
				</Modal>
			)}
			{multiFactorEnabled !== null && (
				<div className="border-2 border-primary-100 rounded-2xl">
					<div className="items-center p-5 my-4 space-y-6 md:flex">
						<img
							src={multiFactorEnabled ? greenMobile : redMobile}
							alt="mobile icon"
							className="m-auto"
						/>
						<div className="ml-4">
							<h2 className="inline-block mb-2 mr-2 text-lg font-bold text-neutral-700">
								Two-Factor authentication (2FA)
							</h2>
							<div className="inline-flex px-2 text-xs tracking-wider rounded-full bg-success-100 text-success-900">
								OPTIONAL
							</div>
							<p>
								Once you set up Two-Factor authentication, we&apos;ll ask for an
								authentication code every time you log into your Bright account.
							</p>
						</div>
						<div className="w-full whitespace-nowrap md:w-auto md:ml-3">
							{multiFactorEnabled ? (
								<LinkButton
									outlineButton
									text="Turn off"
									onClick={() => setShowTurnOffModal(true)}
								/>
							) : (
								<LinkButton text="Turn on" href="/authenticator" />
							)}
						</div>
					</div>
					<div className="flex flex-col justify-between p-4 bg-primary-50 rounded-b-2xl sm:flex-row">
						{multiFactorEnabled ? (
							<LinkButton
								className="mb-4 font-bold underline sm:mb-0"
								text="Get new recovery codes"
								onClick={() =>
									generateNewRecoveryCodes().then(({ recoveryCodes }) =>
										setNewRecoveryCodes(recoveryCodes)
									)
								}
							/>
						) : (
							<div />
						)}
						<a
							className="font-bold text-[#3366CC] underline hover:text-[#3366CC]"
							href="/security/how-2fa-works"
						>
							What is Two-Factor (2FA) authentication?
						</a>
					</div>
				</div>
			)}
		</>
	);
};

export default TwoFactorAuth;
