import React from 'react';
import EmptyState from '@brighthr/component-empty-state';

const EmptyStateMessage = ({ title, text, label, type, testId }) => {
	return (
		<div className="flex flex-col items-center justify-center my-10">
			<EmptyState label={label} size="lg" type={type} data-testid={testId || ''} />

			{title && <h2 className="mb-6 text-xl font-bold text-center">{title}</h2>}
			{text && <p className="text-center text-neutral-700">{text}</p>}
		</div>
	);
};

export default EmptyStateMessage;
